"use strict";

import {
  LiveboardEmbed,
  init,
  AuthType,
  RuntimeFilterOp,
  SearchEmbed,
  Action,
} from "@thoughtspot/visual-embed-sdk";

const DOWNLOAD_ACTIONS = [
  Action.DownloadAsPdf,
  Action.DownloadAsCsv,
  Action.Download,
];

const ThoughtSpotPort = {
  add: function (app) {
    const render = (id, downloadsGated, practiceIdents) => {
      const disabledDownloadActions = {
        disabledActions: DOWNLOAD_ACTIONS,
        disabledActionReason: "Nope",
      };

      const practiceFilter = {
        columnName: "Practice Ident",
        operator: RuntimeFilterOp.EQ,
        values: practiceIdents,
      };

      const liveboardEmbed = new LiveboardEmbed(
        document.getElementById("ts-embed"),
        {
          frameParams: {
            width: "100%",
            height: "100%",
          },
          hidedatasources: true,
          liveboardId: id,
          fullHeight: true,
          visibleActions: DOWNLOAD_ACTIONS,
          ...(downloadsGated ? disabledDownloadActions : {}),
          runtimeFilters: [practiceFilter],
        },
      );

      liveboardEmbed.render();
    };

    const authenticate = () => {
      const promise = new Promise((resolve) => {
        const authenticationHandler = (token) => {
          app.ports.authenticateThoughtSpotPort.unsubscribe(
            authenticationHandler,
          );
          resolve(token);
        };
        app.ports.authenticateThoughtSpotPort.subscribe(authenticationHandler);
      });

      app.ports.onThoughtSpotTokenRequestPort.send(null);
      return promise;
    };

    const initialize = (email) => {
      init({
        thoughtSpotHost: "https://hinthealth.thoughtspot.cloud",
        // For some reason ThoughtSpot does not support Cookieless for builder embedding.
        // authType: AuthType.TrustedAuthToken,
        // However, testing with cookie tokens is quite tricky, as the token get's
        // retrieved from the cookies instead of refreshing on user change.
        authType: AuthType.TrustedAuthTokenCookieless,
        username: email,
        autoLogin: true,
        getAuthToken: authenticate,
        dateFormatLocale: "en-US",
      });
    };

    app.ports.renderThoughtSpotPort.subscribe(
      ({ id, downloadsGated, practiceIdents }) => {
        render(id, downloadsGated, practiceIdents);
      },
    );

    app.ports.embedThoughtSpotPort.subscribe(
      ({ id, email, downloadsGated, practiceIdents }) => {
        initialize(email);

        render(id, downloadsGated, practiceIdents);
      },
    );

    app.ports.embedSearchThoughtSpotPort.subscribe(({ email }) => {
      initialize(email);

      setTimeout(() => {
        const searchEmbed = new SearchEmbed(
          document.getElementById("ts-search-embed"),
          {
            frameParams: {
              width: "100%",
              height: "100%",
            },
            fullHeight: true,
          },
        );

        searchEmbed.render();
      }, 500);
    });
  },
};

module.exports = ThoughtSpotPort;
